/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1c832d29-c544-4d4e-8b62-f9f056b2f667",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xZ6hLvf0z",
    "aws_user_pools_web_client_id": "1ukti8vf40ir2qfaf2rp65auak",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "baconbitsdynamodb-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "baconbitsapi",
            "endpoint": "https://mwd9iaf2bh.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "b592879bf85a42f3a9c88b98ce2d3b1b",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
